<template>
  <sign-page
    title-text="文章类型"
    :request="request"
    table-size="large"
    ref="articleTypePage"
    :titleMenus="[{key: 'new', label: '新增'}]"
    :tableActions="[{key: 'update', label: '修改'}, {key: 'deleter', label: '删除'}]"
    @clickTitleMenu="clickTitleMenu"
    @tableAction="tableAction"
    :column-list="columnList"
    :table-actions-fixed="true"
    :tableActionsWidth="100">
    <type-form ref="typeForm" :data="chooseData" @dataUpdate="dataUpdate"></type-form>
  </sign-page>
</template>

<script>
import TypeForm from './cmp/typeForm'

import {
  articleTypeRequest as request
} from '../../api'

export default {
  components: {
    TypeForm
  },
  computed: {
    columnList () {
      return [{
        field: 'name',
        title: '类型名称'
      },
      {
        field: 'config',
        title: '应用地址'
      }]
    }
  },
  data () {
    return {
      chooseData: {},
      request
    }
  },
  methods: {
    dataUpdate () {
      this.$refs.articleTypePage.loadData()
    },
    async tableAction (p) {
      this.chooseData = p.data
      if (p.action === 'update') {
        this.$refs.typeForm.open()
      } else if (p.action === 'deleter') {
        if (this.chooseData.articleNum) {
          this.$notify({
            title: '系统提示',
            message: '该类型已存在文章无法删除，请先删除文章后重试！',
            type: 'info'
          })
          return
        }
        const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除该文章类型吗?'})
        if (result) {
          await request.del(this.chooseData.id)
          this.$refs.articleTypePage.loadData()
        }
      }
    },
    clickTitleMenu (p) {
      if (p === 'new') {
        this.$refs.typeForm.open()
      }
    }
  }
}
</script>
