<template>
  <fm-modal class="type-form" v-model="modal" v-if="modal" width="600px" title="文章类型">
    <div class="form">
      <div class="form-item">
        <div class="label">类型名称:</div>
        <div class="value">
          <fm-input-new v-model="formData.name" style="width:100%;"></fm-input-new>
        </div>
      </div>
      <div class="form-item">
        <div class="label">应用地址:</div>
        <div class="value">
          <fm-input-new v-model="formData.config" style="width:100%;"></fm-input-new>
        </div>
      </div>
    </div>
    <div class="btns">
      <fm-btn @click="save" style="margin-right: 30px;">保存</fm-btn>
      <fm-btn @click="modal = false">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
import {
  articleTypeRequest
} from '../../../api'

export default {
  props: {
    data: Object
  },
  watch: {
    data: {
      handler (value) {
        if (value) {
          this.formData = Object.assign({}, value)
        } else {
          this.formData = {}
        }
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      formData: {},
      modal: false,
      loading: false
    }
  },
  methods: {
    async save () {
      if (!this.formData.name) {
        this.$notify({
          title: '系统提示',
          message: '请输入类型名称',
          type: 'info'
        })
        return
      }
      if (!this.formData.id) {
        await articleTypeRequest.add(this.formData)
      } else {
        await articleTypeRequest.update(this.formData.id, this.formData)
      }
      this.modal = false
      this.$emit('dataUpdate')
    },
    open () {
      this.modal = true
    }
  }
}
</script>

<style lang="less" scoped>
.type-form {
  .form {
    padding: 0 20px;
  }
  .btns {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .add-key {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .form-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .label {
      width: 7rem;
    }
    .value {
      flex: 1;
    }
  }
}
</style>